"use client";


import { ThemeSwitchButton } from "./theme-switch-button";
import { HStack } from "@/styled-system/jsx";
import { center } from "@/styled-system/patterns";

import Link from "next/link";

import { Box, Portal, rem } from "@mantine/core";
import { usePathname } from "next/navigation";
import { Suspense, useEffect, useState } from "react";
import SignOutButton from "../Button/SignOutButton";
import SignInButton from "../Button/SignInButton";
import Logo from "../Icons/Logo";
import LogoLetter from "../Icons/LogoLetter";


export const DesktopNavBar = ({ session }) => {
  const pathname = usePathname();
  const [page, setPage] = useState(undefined);

  useEffect(() => {
    const pageFalse = () => {
      setPage(false);
    };
    const pageTrue = () => {
      setPage(true);
    };

    if (pathname === "/disc-search") {
      pageTrue();
    } else {
      pageFalse();
    }
  });

  return (
    <Portal>
      <Box
        display={{ base: "none", sm: "block" }}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: rem(60),
          zIndex: 10,
        }}
      >
        <HStack
          height="18"
          py="8"
          pr="10"
          pl="10"
          position="relative"
          justify="space-between"
          backdropFilter="auto"
          backdropBlur="sm"
          bg={{ base: "lightAlpha", _dark: "darkAlpha" }}
        >
          <Link
            href="/"
            prefetch={false}
            className={center({ flexShrink: "0" })}
            aria-label="Home Page"
          >
            <Logo />
          </Link>
          <HStack gap="4">

            {!session ? <SignInButton /> : <SignOutButton />}
            <ThemeSwitchButton />
          </HStack>
        </HStack>
      </Box>

    </Portal>
  );
};
