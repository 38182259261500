"use client";
import {
  ActionIcon,
  Anchor,
  Box,
  Center,
  em,
  Flex,
  Group,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import LogoLetter from "src/components/Icons/LogoLetter";
import FacebookIcon from "@/components/Icons/FacebookIcon";
import Mail from "@/components/Icons/Mail";
import InstaIcon from "@/components/Icons/InstaIcon";

const links = [
  { link: "/privacy", label: "Privacy" },
  { link: "/terms", label: "Terms" },
];

export default function Footer() {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const items = links.map((link) => (
    <Anchor
      c="dimmed"
      component="a"
      key={link.label}
      href={link.link}
      lh={1}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <>
      <Box style={{
        position: "absolute",
        bottom: "0",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100vw",
      }}>
        <Center mt={"50px"}>
          <Flex
            gap={isMobile ? "xl" : "30vw"}
            justify="center"
            align="center"
            direction="row"
            wrap="nowrap"
            pt="25px"
          >
            <LogoLetter />

            <Group wrap="nowrap">{items}</Group>

            <Group gap="xs" justify="flex-end" wrap="nowrap">
              <ActionIcon
                component="a"
                size="lg"
                variant="default"
                radius="xl"
                href="https://www.facebook.com/people/Stacks-on-Stacks/100089439401540/"
                aria-label="Facebook Link"
                bg="var(--colors-bg-card)"
              >
                <FacebookIcon />
              </ActionIcon>
              <ActionIcon
                component="a"
                size="lg"
                variant="default"
                radius="xl"
                href="https://www.instagram.com/stacksonstacksdg/"
                aria-label="Instagram Link"
                bg="var(--colors-bg-card)"
              >
                <InstaIcon />
              </ActionIcon>
              <ActionIcon
                component="a"
                size="lg"
                variant="default"
                radius="xl"
                href="mailto:info@stacks-on-stacks.com"
                aria-label="Mail Link"
                bg="var(--colors-bg-card)"
              >
                <Mail />
              </ActionIcon>
            </Group>
          </Flex>
        </Center>
        <Center pb="50px" mt="25px">
          <Text size="14px" c="dimmed">
            © 2024 Stacks on Stacks LLC
          </Text>
        </Center>
      </Box>
    </>
  );
}
